import { ElementRef, Injectable } from '@angular/core';
// import { ResumableState, ResumableSubject } from 'app/shared/resumable';
// import { ResumableUploadService } from 'app/shared/resumable-upload.service';
import {
    AssetAdjustmentsService,
    GltfExportOptions,
    KeyValueAnyPair,
    ResourceJsonParams,
} from 'asset-adjustments';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../shared/utils.service';
import { RestService } from '../communication/rest.service';
import { AuthService } from '../auth/auth.service';
import { UploadResource } from '../product/product';
import { ResumableSubject, ResumableState } from '../shared/resumable';
import {
    BroadcasterNotification,
    BroadcasterNotificationType,
} from '../communication/broadcaster-notifications';
import { FormatsType } from './enums';
import { ResumableUploadService } from './resumable-upload.service';
import { AdjustmentsPreset } from '../retailer/retailer';

@Injectable({
    providedIn: 'root',
})
export class AssetAdjustmentsHelperService {
    public catchEmbed: boolean;
    public shareState: boolean;
    public embedLink: string;
    public isViewerFullyLoaded: boolean;
    public currentPreset: AdjustmentsPreset;
    public noDamping: boolean;
    public presets: Array<AdjustmentsPreset>;
    private _exportProgress: number;
    private onAutoAdjustSceneResolve: (
        value: void | PromiseLike<string>
    ) => void;
    private onLightsSummary: ((value: void | PromiseLike<void>) => void)[] = [];
    private _imagesDictionary = {} as { [id: string]: KeyValueAnyPair };
    constructor(
        // private assetAdjustments: AssetAdjustmentsService,
        private assetAdjustmentsService: AssetAdjustmentsService,
        private utils: UtilsService,
        private rest: RestService,
        private resumableUpload: ResumableUploadService,
        // private pixels: PixelsService,
        private auth: AuthService,
        private broadcaster: BroadcasterService
    ) {
        this._imagesDictionary = {};
    }

    get exportProgress() {
        return this._exportProgress;
    }

    set exportProgress(value: number) {
        this._exportProgress = value;
        if (window.parent && document.referrer) {
            let msgObj = {
                action: 'setExportProgress',
                data: {
                    value: this._exportProgress,
                },
            };
            window.parent.postMessage(
                JSON.stringify(msgObj),
                document.referrer
            );
        }
    }

    async onResourceExport(obj: any) {
        return new Promise((resolve: any, reject: any) => {
            if (!this.catchEmbed) {
                resolve();
                return;
            }
            let res = {
                archive: 'zip', // obj.type,
                file: obj.file,
                resource_type: FormatsType.GLB, //7, // 7 => GLB, 6 => glTF
                encrypt: false, // true,
                compress: false,
                mesh_compress: false, // this.assetAdjustmentsService.meshCompress,
                as_base64: true,
                // json_params: this.utils.getResourceJsonParams(obj.json_params, FormatsType.GLB),
                target_resource_type: FormatsType.GLB, // obj.targetResourceType
            } as UploadResource;
            this.uploadNewResource(res, resolve);
        });

        // this.meshCompress = false;
    }

    revealLink(link: string) {
        this.shareState = false;
        this.embedLink = link;
    }

    uploadNewResource(resource: UploadResource, resolve: Function) {
        let onSuccess = (resource: UploadResource) => {
            delete this.exportProgress;
            resolve(resource);
            // let params = this.utils.getParamsFromUrl(this.assetAdjustments.getCurrentSrc());
            // resource.viewer_url = this.utils.setUrlParam(resource.viewer_url, 'tv', '112');
            // resource.viewer_url = this.utils.setUrlParam(resource.viewer_url, 'json-data', new Date().getTime().toString());
            // if (this.assetAdjustmentsService.exportedQuery) {
            //   resource.viewer_url = this.utils.getCleanViewerURL(resource.viewer_url, this.assetAdjustmentsService.exportedQuery);
            //   resource.viewer_url = this.utils.getCleanViewerURL(resource.viewer_url, params);
            //   resource.viewer_url = this.utils.removeForbiddenViewerURL(resource.viewer_url);
            //   resource.update_json_params = true;
            //   this.putResource(resource);
            //   delete this.assetAdjustmentsService.exportedQuery;
            //   this.assetAdjustmentsService.resetDefaults();
            // }
            // else {
            //   resource.viewer_url = this.utils.getCleanViewerURL(resource.viewer_url, params);
            //   this.putResource(resource);
            //   // this.revealLink(resource.viewer_url);
            // }
        };
        let file = null;
        // if (resource.buffer)
        //   file = new Blob([resource.buffer], { type: 'application/octet-stream' });
        // else
        file = new Blob([this.utils.base64ToArrayBuffer(resource.file)], {
            type: 'application/octet-stream',
        });

        let sub = this.resumableUpload.sourceFiles(file);
        this.exportProgress = 1;
        sub.subscribe((res: ResumableSubject) => {
            switch (res.state) {
                case ResumableState.FILE_PROGRESS: {
                    this.exportProgress = res.object.progress;
                    if (window.top != window.self) {
                        let msgObj = {
                            action: 'setExportProgress',
                            data: {
                                progress: this.exportProgress,
                            },
                        };
                        window.parent.postMessage(
                            JSON.stringify(msgObj),
                            document.referrer
                        );
                    }
                    break;
                }
                case ResumableState.ERROR: {
                    console.warn(res);
                    console.warn(
                        'Something went wrong during upload of a specific file, ERROR . . .'
                    );
                    let data: BroadcasterNotification = {
                        text: 'Connection was interrupted, please try again.',
                        type: BroadcasterNotificationType.Error,
                        action: 'OK',
                    };
                    this.broadcaster.broadcast('notifyUser', data);
                    break;
                }
                case ResumableState.COMPLETE: {
                    let payload = {
                        uploaded_file_url: res.object.message,
                        uploaded_file_name: 'scene.glb',
                        archive: 'glb',
                        resource_type: FormatsType.GLB, // 7, // 7 => GLB, 6 => glTF
                        encrypt: false,
                        compress: false,
                        mesh_compress: false, // this.assetAdjustmentsService.meshCompress,
                        // org_scene: true,
                        // as_base64: true,
                        // quantize_texcoord_bits: resource.quantize_texcoord_bits,
                        // quantize_normal_bits: resource.quantize_normal_bits,
                        // as_zip: true,
                        // as_gzip: true,
                        target_resource_type: 7,
                    } as UploadResource;

                    this.rest.afterResource('post', payload).subscribe(
                        (res: Array<UploadResource>) => {
                            onSuccess(res[0]);
                        },
                        (err) =>
                            this.utils.httpErrorResponseHandler(
                                err,
                                'failure uploading model'
                            )
                    );
                    break;
                }
            }
        });
    }

    // putResource(resource: Resource, successCB?: Function, failureCB?: Function) {
    //   this.rest.artistResource('put', resource, '/' + resource.id).subscribe(
    //     (resource: Resource) => {
    //       // for (let i = 0; i < this.currentArtistsjobitem.artists_jobs_resources.length; i++) {
    //       //   if (this.currentArtistsjobitem.artists_jobs_resources[i].id == resource.id)
    //       //     this.currentArtistsjobitem.artists_jobs_resources[i] = resource;
    //       // }
    //       // this.initResources();
    //       if (typeof successCB === 'function')
    //         successCB();
    //       this.revealLink(resource.viewer_url);
    //     },
    //     err => {
    //       this.utils.httpErrorResponseHandler(err, 'failure');
    //       if (typeof failureCB === 'function')
    //         failureCB();
    //     }
    //   );
    // }

    mapService(
        iframeModel: ElementRef,
        embed: boolean,
        resourceType = FormatsType.GLB,
        viewerType = '3js'
    ) {
        this.assetAdjustmentsService.iframeModel = iframeModel;
        this.assetAdjustmentsService.embed = embed;
        this.assetAdjustmentsService.resourceType = resourceType;
        this.assetAdjustmentsService.viewerType = viewerType;
        // this.assetAdjustmentsService.changeSrc = this.changeSrc;
        // this.assetAdjustmentsService.createdAt = this.createdAt;
        // this.assetAdjustmentsService.changeBB = this.changeBB;
    }

    unmapService() {
        delete this.assetAdjustmentsService.iframeModel;
        delete this.assetAdjustmentsService.embed;
        delete this.assetAdjustmentsService.resourceType;
        delete this.assetAdjustmentsService.viewerType;
    }

    shareEmbed() {
        if (this.shareState) return;
        // this.pixels.sendPixel({
        //   event_type: 'shareEmbed',
        //   reference_id: this.auth.user ? this.auth.user.id : null
        // });
        this.shareState = true;
        if (this.auth.user) {
            this.catchEmbed = true;
            let options = {
                downloadFile: false,
            } as GltfExportOptions;
            this.assetAdjustmentsService.export(options);
            // this.assetAdjustmentsService.export(null, null, null, null, false);
        }
    }

    async getScreenshot(): Promise<string> {
        return new Promise((resolve: any, reject: any) => {
            let onAdjustmentMessageSub =
                this.assetAdjustmentsService.broadcaster.subscribe(
                    (message: any) => {
                        if (message.key == 'setCurrentScreenshot') {
                            onAdjustmentMessageSub.unsubscribe();
                            resolve(message.data);
                        }
                    }
                );
            this.assetAdjustmentsService.getCurrentScreenshot();
        });
    }

    public setIFrameModel(iframeModel: ElementRef<HTMLIFrameElement>): void {
        this.assetAdjustmentsService.iframeModel = iframeModel;
    }

    private applyPresetSrc(
        src = this.assetAdjustmentsService.iframeModel.nativeElement.src
    ): void {
        let params = this.currentPreset.preset_json.urlParats;
        for (let i in params) {
            src = this.utils.setUrlParam(src, i, params[i]);
        }
        const oldSrc =
            this.assetAdjustmentsService.iframeModel.nativeElement.src;
        this.assetAdjustmentsService.iframeModel.nativeElement.src = src;
        this.initParams(oldSrc);
        this.assetAdjustmentsService.applyUrlParams();
    }

    initParams(oldSrc?: string): void {
        this.assetAdjustmentsService.init(oldSrc);
        if (
            this.assetAdjustmentsService.parameters &&
            this.assetAdjustmentsService.parameters.length
        ) {
            const sw = this.assetAdjustmentsService.parameters.find(
                (p) => p.id === 'shaded-wireframe'
            );
            if (sw) {
                sw.color = '#000000';
                sw.colorChanged = true;
            }
        }
    }

    async applyPreset(
        src = this.assetAdjustmentsService.iframeModel.nativeElement.src
    ): Promise<string> {
        return new Promise((resolve: any, reject) => {
            if (this.currentPreset) {
                this.applyPresetSrc(src);
                this.onLightsSummary.push(async () => {
                    await this.assetAdjustmentsService.broadcastSceneSummary();
                    if (this.currentPreset && this.currentPreset.preset_json) {
                        if (this.currentPreset.preset_json.lights) {
                            await this.assetAdjustmentsService.setLightsByJson(
                                this.currentPreset.preset_json.lights
                            );
                        }
                        if (this.currentPreset.preset_json.shadowPlane) {
                            this.assetAdjustmentsService.shadowPlane =
                                this.currentPreset.preset_json.shadowPlane;
                            this.assetAdjustmentsService.applyShadowPlane();
                        }
                        if (this.currentPreset.preset_json.hdri) {
                            if (!this.currentPreset.preset_json.hdri.type) {
                                this.currentPreset.preset_json.hdri.intensity = 0;
                            }
                            this.assetAdjustmentsService.hdri =
                                this.currentPreset.preset_json.hdri;
                            this.assetAdjustmentsService.applyHDRI();
                        }
                        if (this.currentPreset.preset_json.params) {
                            this.assetAdjustmentsService.params =
                                this.currentPreset.preset_json.params;
                            this.assetAdjustmentsService.applyParams();
                        }
                    }
                    resolve(
                        this.assetAdjustmentsService.iframeModel.nativeElement
                            .src
                    );
                });
                this.listen();
                this.onAutoAdjustSceneResolve = resolve;
            }
        });
    }

    private listen(): void {
        this.assetAdjustmentsService.broadcaster.subscribe(
            this.onAdjustmentMessage.bind(this)
        );
    }

    private async onAdjustmentMessage(message: any): Promise<void> {
        switch (message.key) {
            case 'onAutoAdjustScene': {
                setTimeout(() => {
                    this.assetAdjustmentsService.applyUrlParams();
                    if (this.onAutoAdjustSceneResolve) {
                        this.onAutoAdjustSceneResolve(
                            this.assetAdjustmentsService.iframeModel
                                .nativeElement.src
                        );
                        delete this.onAutoAdjustSceneResolve;
                    }
                }, 1000);
                break;
            }
            case 'viewerFullyLoaded': {
                break;
            }
        }
    }

    // This method will replace the base64 images om the json_params to URL's but they will have a Creators3D domain so we cannot use it without a server side solution
    async onBeforeSave(
        params: ResourceJsonParams
    ): Promise<ResourceJsonParams> {
        return new Promise(async (resolve: any, reject: any) => {
            const bucket = 'cdn.hexa3d.io';
            if (params.scene.texturesAnimations) {
                for (let i in params.scene.texturesAnimations) {
                    for (let j in params.scene.texturesAnimations[i]) {
                        if (
                            params.scene.texturesAnimations[i][j].image &&
                            params.scene.texturesAnimations[i][j].image.indexOf(
                                'data:'
                            ) === 0
                        ) {
                            const base64 =
                                params.scene.texturesAnimations[i][j].image;
                            if (!this._imagesDictionary[base64])
                                this._imagesDictionary[base64] =
                                    {} as KeyValueAnyPair;
                            params.scene.texturesAnimations[i][j].image =
                                this._imagesDictionary[base64].key ||
                                (await this.resumableUpload.getUrlFromBase64(
                                    params.scene.texturesAnimations[i][j].image,
                                    bucket
                                ));
                            this._imagesDictionary[base64].key =
                                params.scene.texturesAnimations[i][j].image;
                        }
                    }
                }
            }
            if (params.scene.materialManipulations) {
                for (let i in params.scene.materialManipulations) {
                    if (
                        params.scene.materialManipulations[i].envMap &&
                        params.scene.materialManipulations[i].envMap.imageSrc &&
                        params.scene.materialManipulations[
                            i
                        ].envMap.imageSrc.indexOf('data:') === 0
                    ) {
                        const base64 =
                            params.scene.materialManipulations[i].envMap
                                .imageSrc;
                        if (!this._imagesDictionary[base64])
                            this._imagesDictionary[base64] =
                                {} as KeyValueAnyPair;
                        params.scene.materialManipulations[i].envMap.imageSrc =
                            this._imagesDictionary[base64].key ||
                            (await this.resumableUpload.getUrlFromBase64(
                                await this.utils.convertToJpeg(base64, 0.9),
                                bucket
                            ));
                        this._imagesDictionary[base64].key =
                            params.scene.materialManipulations[
                                i
                            ].envMap.imageSrc;
                    }
                }
            }
            if (params.scene.meshActions) {
                for (let i in params.scene.meshActions) {
                  if (params.scene.meshActions[i].img && params.scene.meshActions[i].img.indexOf('data:') === 0) {
                    const base64 = params.scene.meshActions[i].img;
                    if (!this._imagesDictionary[base64])
                      this._imagesDictionary[base64] = {} as KeyValueAnyPair;
                    params.scene.meshActions[i].img = this._imagesDictionary[base64].key || await this.resumableUpload.getUrlFromBase64(base64, bucket);
                    this._imagesDictionary[base64].key = params.scene.meshActions[i].img;
                  }
                  if (params.scene.meshActions[i].soundEffect && params.scene.meshActions[i].soundEffect.indexOf('data:') === 0) {
                    const base64 = params.scene.meshActions[i].soundEffect;
                    if (!this._imagesDictionary[base64])
                      this._imagesDictionary[base64] = {} as KeyValueAnyPair;
                    params.scene.meshActions[i].soundEffect = this._imagesDictionary[base64].key || await this.resumableUpload.getUrlFromBase64(base64, bucket);
                    this._imagesDictionary[base64].key = params.scene.meshActions[i].soundEffect;
                  }
                }
              }
            if (params.scene.annotations) {
                for (let i in params.scene.annotations) {
                    if (
                        params.scene.annotations[i].img &&
                        params.scene.annotations[i].img.indexOf('data:') === 0
                    ) {
                        const base64 = params.scene.annotations[i].img;
                        if (!this._imagesDictionary[base64])
                            this._imagesDictionary[base64] =
                                {} as KeyValueAnyPair;
                        params.scene.annotations[i].img =
                            this._imagesDictionary[base64].key ||
                            (await this.resumableUpload.getUrlFromBase64(
                                base64,
                                bucket
                            ));
                        this._imagesDictionary[base64].key =
                            params.scene.annotations[i].img;
                    }
                }
            }
            if (params.scene.meshFurOptions?.diffuseTexture) {
                if (params.scene.meshFurOptions.diffuseTexture.indexOf('data:') === 0) {
                  const base64 = params.scene.meshFurOptions.diffuseTexture;
                  if (!this._imagesDictionary[base64])
                    this._imagesDictionary[base64] = {} as KeyValueAnyPair;
                  params.scene.meshFurOptions.diffuseTexture = this._imagesDictionary[base64].key || await this.resumableUpload.getUrlFromBase64(params.scene.meshFurOptions.diffuseTexture, bucket);
                  this._imagesDictionary[base64].key = params.scene.meshFurOptions.diffuseTexture;
                }
              }
            resolve(params);
        });
    }

    // onNewPresetChange(name: string, adjustmentsData: AdjustmentsData) {
    //   if (!name) {
    //     this.utils.notifyUser({
    //       text: 'Enter preset name first',
    //       type: NotificationType.Error
    //     })
    //     return;
    //   }
  
    //   if (!this.presets) {
    //     this.presets = [];
    //   }
  
    //   this.currentPreset = {
    //     artist_user_id: this.auth.user.id,
    //     preset_name: name,
    //     type_id: this.savePresetFor === SAVE_PRESET_FOR.PERSONAL ? ArtistsUsersPresetType.PRIVATE : ArtistsUsersPresetType.PUBLIC
    //   };
  
    //   if (this.currentPreset.type_id !== ArtistsUsersPresetType.PRIVATE) {
    //     if (this.savePresetFor === SAVE_PRESET_FOR.PRODUCT_SUB_CATEGORY) {
    //       this.currentPreset.sub_category_id = adjustmentsData.subCategoryId;
    //     } else if (this.savePresetFor === SAVE_PRESET_FOR.RETAILER_SUB_CATEGORY) {
    //       this.currentPreset.retailer_sub_category_id = adjustmentsData.retailerSubCategoryId;
    //     } else {
    //       this.currentPreset.retailer_id = adjustmentsData.retailerId;
    //     }
    //   }
  
    //   this.presets.push(this.currentPreset);
    // }
  
    // async getPresetJSON(): Promise<AdjustmentsPresetJson> {
    //   return new Promise(async (resolve: any, reject: any) => {
    //     const res = {} as AdjustmentsPresetJson;
    //     this.assetAdjustmentsService.recoverMuteLights();
    //     let src = this.assetAdjustmentsService.getCurrentSrc();
    //     src = this.utils.setUrlParam(src, 'load', null);
    //     // src = this.utils.setUrlParam(src, 'tv', null);
    //     src = this.utils.setUrlParam(src, 'zip', null);
    //     src = this.utils.setUrlParam(src, 'gzip', null);
    //     src = this.utils.setUrlParam(src, 'br', null);
    //     src = this.utils.setUrlParam(src, 'decrypt', null);
    //     src = this.utils.setUrlParam(src, 'autorotate', null);
    //     src = this.utils.setUrlParam(src, 'webp', null);
    //     src = this.utils.setUrlParam(src, 'compressed', null);
    //     src = this.utils.setUrlParam(src, 'json-data', null);
    //     src = this.utils.setUrlParam(src, 'config', null);
    //     res.urlParats = this.utils.getParamsFromUrl(src);
    //     const jp = await this.assetAdjustmentsService.getJsonParamsAsync();
    //     res.lights = jp.scene.lights;
    //     res.shadowPlane = jp.scene.shadowPlane;
    //     res.hdri = jp.scene.hdri;
    //     res.params = jp.scene.params;
    //     resolve(res);
    //   });
    // }
  
    // async saveCurrentPreset(adjustmentsData: AdjustmentsData) {
    //   if (!this.currentPreset) {
    //     this.utils.notifyUser({
    //       text: 'Please select an exsting preset or define a new one',
    //       type: NotificationType.Error
    //     })
    //     return;
    //   }
    //   if (this.auth.user && this.currentPreset.artist_user_id != this.auth.user.id) {
    //     this.utils.notifyUser({
    //       text: `You are unauthorized to edit someone else's preset`,
    //       type: NotificationType.Error
    //     })
    //     return;
    //   }
    //   this._presetLoading = true;
    //   this.currentPreset.preset_json = await this.getPresetJSON();
    //   if (this.currentPreset.preset_json.hdri && !this.currentPreset.preset_json.hdri.type) {
    //     this.currentPreset.preset_json.hdri.intensity = 0;
    //   }
    //   const query = this.currentPreset.id ? `/${this.currentPreset.id}` : '';
    //   if (!this.rolesHelper.isSuperUserLoggedIn()) {
    //     this.savePresetFor = this.PERSONAL;
    //   }
    //   this.currentPreset.type_id = this.savePresetFor === this.PERSONAL ? ArtistsUsersPresetType.PRIVATE : ArtistsUsersPresetType.PUBLIC;
    //   if (this.savePresetFor === this.RETAILER_SUB_CATEGORY) {
    //     this.currentPreset.retailer_sub_category_id = adjustmentsData.retailerSubCategoryId;
    //   } else {
    //     this.currentPreset.retailer_sub_category_id = null;
    //   }
    //   if (this.savePresetFor === this.PRODUCT_SUB_CATEGORY) {
    //     this.currentPreset.sub_category_id = adjustmentsData.subCategoryId;
    //   } else {
    //     this.currentPreset.sub_category_id = null;
    //   }
    //   if (this.savePresetFor === this.RETAILER) {
    //     this.currentPreset.retailer_id = adjustmentsData.retailerId;
    //   } else {
    //     this.currentPreset.retailer_id = null;
    //   }
  
    //   const method = this.currentPreset.id ? 'put' : 'post';
    //   this.rest.adjustmentsPresets(method, this.currentPreset, query).subscribe({
    //     next: (preset: AdjustmentsPreset) => {
    //       this.auth.updateAdjustmetnsPreset(true);
    //       this.currentPreset.id = preset.id;
    //       if (this.currentPreset.type_id !== ArtistsUsersPresetType.PRIVATE) {
    //         this.retailerCategoriesService.getSubCategories(true);
    //         this.categoriesService.getFullCategoriesAsync(true);
    //       }
    //     },
    //     error: err => this.utils.httpErrorResponseHandler(err, 'failure saving preset'),
    //     complete: () => this._presetLoading = false
    //   });
    // }
  
    // deleteCurrentPreset() {
    //   if (this.currentPreset) {
    //     if (this.currentPreset.id) {
    //       this._presetLoading = true;
    //       let query = `/${this.currentPreset.id}`;
    //       this.rest.adjustmentsPresets('delete', null, query).subscribe(
    //         preset => {
    //           this.auth.updateAdjustmetnsPreset(false);
    //           delete this.currentPreset;
    //         },
    //         err => this.utils.httpErrorResponseHandler(err, 'failure saving preset'),
    //         () => this._presetLoading = false
    //       );
    //     }
    //     else {
    //       for (let i = 0; i < this.auth.user.artists_users_presets.length; i++) {
    //         if (this.auth.user.artists_users_presets[i].preset_name == this.currentPreset.preset_name) {
    //           this.auth.user.artists_users_presets.splice(i, 1);
    //           break;
    //         }
    //       }
    //     }
    //   }
    // }
  
    // async getRetailerPresets(jobId: number): Promise<Array<AdjustmentsPreset>> {
    //   const presets = await this.utils.observableToPromise(this.gql.jobRetailerPresets(jobId)) as ApolloQueryResult<JobQueryData>;
    //   return this.utils.deepCopyByValue(presets.data.artists_jobs.artists_jobs_items[0].artists_items[0].products[0].retailers[0].artists_users_presets);
    // }
  
    // async getPresets(options: AdjustmentsPresetOptions): Promise<Array<AdjustmentsPreset>> {
    //   return new Promise(async (resolve: any, reject: any) => {
    //     let res: AdjustmentsPreset[] = [];
  
    //     // First we will add the retailer sub category presets
    //     if (options.retailer_sub_category_id) {
    //       const retailerSubCategories = await this.retailerCategoriesService.getSubCategories(true);
    //       const sc = retailerSubCategories.find(rsc => rsc.id === options.retailer_sub_category_id);
    //       if (sc && sc.artists_users_presets && sc.artists_users_presets.length) {
    //         res = res.concat(this.utils.deepCopyByValue(sc.artists_users_presets));
    //       }
    //     }
  
    //     // Next we will add the retailer presets
    //     if (options.retailer_id) {
    //       try {
    //         const retailerPresets = await this.getRetailerPresets(options.job_id);
    //         if (retailerPresets.length) {
    //           res = res.concat(retailerPresets);
    //         }
    //       } catch (err) {
    //         reject(err);
    //       }
    //     }
  
    //     // Next we will add the product sub category presets
    //     if (options.sub_category_id) {
    //       await this.categoriesService.getFullCategoriesAsync(true);
    //       const hasPresetCategories = !!this.categoriesService.subCategoriesDictionary[options.sub_category_id]?.artists_users_presets?.length;
    //       if (hasPresetCategories) {
    //         res = res.concat(this.utils.deepCopyByValue(this.categoriesService.subCategoriesDictionary[options.sub_category_id].artists_users_presets));
    //       }
    //     }
  
    //     if (options.type_id === ArtistsUsersPresetType.PUBLIC) {
    //       if (this.auth.isloggedIn()) {
    //         await this.auth.updateAdjustmetnsPreset(true);
    //         res = res.concat(this.utils.deepCopyByValue(this.auth.user.artists_users_presets.filter(p => p.type_id === ArtistsUsersPresetType.PRIVATE)));
    //       }
    //     }
  
    //     res.sort((a, b) => a.sort_index - b.sort_index);
    //     res.sort((a, b) => (b.sub_category_id ? 1 : 0) - (a.sub_category_id ? 1 : 0));
    //     res.sort((a, b) => (b.retailer_sub_category_id ? 1 : 0) - (a.retailer_sub_category_id ? 1 : 0));
  
    //     resolve(res);
    //   });
    // }

    onDestroy() {
        // this.onAdjustmentMessageSubs.forEach(s => s.unsubscribe());
        delete this.embedLink;
        delete this.isViewerFullyLoaded;
        delete this.exportProgress;
        delete this.currentPreset;
        delete this.onAutoAdjustSceneResolve;
        // delete this.forceOffscreen;
        // this.savePresetFor = this.PERSONAL;
        // this.onViewerFullyLoaded = [];
        // this._imagesDictionary = {};
    }
}
